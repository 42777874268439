<template>
    <div>
        <form @submit.prevent="updateRecord">

            <!-- paste from hq below here -->

            <div class="">
                <h1>Appt Detail</h1>
                <div>
                    <Checkbox
                        class="flex center bottom-15"
                        id="active"
                        value=1
                        v-model="record_data.active"
                        label="Active"
                        :disabled="!isEditable"
                    /></div>
                <div class="flex colx4 wrap">
                    <div><Input
                        type="date"
                        name="dayt_appt_start"
                        id="dayt_appt_start"
                        placeholder="Dayt Appt Start"
                        v-model="record_data.dayt_appt_start"
                        label="Dayt Appt Start"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="date"
                        name="dayt_appt_end"
                        id="dayt_appt_end"
                        placeholder="Dayt Appt End"
                        v-model="record_data.dayt_appt_end"
                        label="Dayt Appt End"
                        :disabled="!isEditable"
                    /></div>
                    <div><ClientsDropDown
                        v-model="record_data.client_id"
                        :readonly="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="video_id"
                        id="video_id"
                        placeholder="Video Id"
                        v-model="record_data.video_id"
                        label="Video Id"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="user_ids"
                        id="user_ids"
                        placeholder="User Ids"
                        v-model="record_data.user_ids"
                        label="User Ids"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="group_uuid"
                        id="group_uuid"
                        placeholder="Group Uuid"
                        v-model="record_data.group_uuid"
                        label="Group Uuid"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="room_id"
                        id="room_id"
                        placeholder="Room Id"
                        v-model="record_data.room_id"
                        label="Room Id"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="date"
                        name="client_confirmed_dayt"
                        id="client_confirmed_dayt"
                        placeholder="Client Confirmed Dayt"
                        v-model="record_data.client_confirmed_dayt"
                        label="Client Confirmed Dayt"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="appt_status"
                        id="appt_status"
                        placeholder="Appt Status"
                        v-model="record_data.appt_status"
                        label="Appt Status"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="date"
                        name="client_signin_dayt"
                        id="client_signin_dayt"
                        placeholder="Member Signin Dayt"
                        v-model="record_data.client_signin_dayt"
                        label="Member Signin Dayt"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="appt_machine"
                        id="appt_machine"
                        placeholder="Appt Machine"
                        v-model="record_data.appt_machine"
                        label="Appt Machine"
                        :disabled="!isEditable"
                    /></div>
                    <div>
                        <Checkbox
                            id="appt_complete"
                            value=1
                            v-model="record_data.appt_complete"
                            label="Appt Complete"
                            :disabled="!isEditable"
                        /></div>
                    <div>
                        <Checkbox
                            id="recurring_appt"
                            value=1
                            v-model="record_data.recurring_appt"
                            label="Recurring Appt"
                            :disabled="!isEditable"
                        /></div>
                    <div><Input
                        type="text"
                        name="recurrence_exceptions"
                        id="recurrence_exceptions"
                        placeholder="Recurrence Exceptions"
                        v-model="record_data.recurrence_exceptions"
                        label="Recurrence Exceptions"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="recurring_parent_id"
                        id="recurring_parent_id"
                        placeholder="Recurring Parent Id"
                        v-model="record_data.recurring_parent_id"
                        label="Recurring Parent Id"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="recurring_interval"
                        id="recurring_interval"
                        placeholder="Recurring Interval"
                        v-model="record_data.recurring_interval"
                        label="Recurring Interval"
                        :disabled="!isEditable"
                    /></div>
                    <div><Input
                        type="text"
                        name="appointment"
                        id="appointment"
                        placeholder="Appointment"
                        v-model="record_data.appointment"
                        label="Appointment"
                        :disabled="!isEditable"
                    /></div>
                </div>
                <!--form_extra-->
            </div>


            <ConfirmButton @click="updateRecord()"></ConfirmButton>
        </form>
    </div>
</template>
<script>
    const api_root = 'appts'

    export default {
        name: 'AppointmentDetail',
        props: {
            record_id: [String, Number],
        },
        data() {
            return {
                record_data : {
                    active : 0,
                    dayt_appt_start : 'dayt',
                    dayt_appt_end : 'dayt',
                    client_id : 1,
                    video_id : 1,
                    user_ids : '',
                    group_uuid : '',
                    room_id : 1,
                    client_confirmed_dayt : 'dayt',
                    appt_status : '',
                    client_signin_dayt : 'dayt',
                    appt_machine : '',
                    appt_complete : 0,
                    recurring_appt : 0,
                    recurrence_exceptions : '',
                    recurring_parent_id : 1,
                    recurring_interval : '',
                    appointment : '',

                },

                isEditable : 1,

                record_actions : {
                    getRecordDetails: (id) => `/${api_root}/${id}`,
                    updateRecord: (id) => `/${api_root}/${id}`,
                }
            }
        },
        methods: {
            async init() {
                if (!(this.record_id === 'new')) {
                    const res = await this.$api.get(this.record_actions.getRecordDetails(this.record_id))

                    if (res.status === 404 || res.status === 500) {
                        this.$toasted.error('Could not find record')
                        return this.$router.replace({ name: 'NotFound' })
                    } else {
                        this.record_data = res.data.info[0]
                    }

                }
            },
            async updateRecord(e) {
                e.preventDefault()
                const isValid = await this.validateForm()

                if (isValid) {
                    try {
                        await this.$api.put(this.record_actions.updateRecord(this.record_id), this.record_data)
                        this.$toasted.success('Successfully updated Record.')
                    } catch (err) {
                        this.$toasted.error('Failed to update Record. Please try again later.')
                    }
                }
            },
            async validateForm() {
                const isValid = 1 // await this.$refs.form.validate()

                if (!isValid) {
                    this.$toasted.error('Please fill out all required fields.')
                }

                return isValid
            },
        },
        async created() {
            await this.init()
        },
    }
</script>
